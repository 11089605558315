<template>
  <div class="w100">
    <form class="form pt-3" @submit.prevent="onSubmit()">
      <div class="form-content" style="overflow: auto">
        <template v-for="input in inputs.filter((i) => !i.isHide)">
          <div
            :key="input.key"
            v-if="input.type == 'title'"
            class="d-block w-100"
          >
            <p class="bold my-2 px-2">
              {{ input.title }}
              <span v-if="input.required" class="text-danger">*</span>
            </p>
          </div>
          <div
            :key="input.key + '11'"
            v-else-if="input.type == 'hr'"
            class="d-block w-100"
          >
            <hr class="mb-2" />
          </div>
          <div
            v-else
            class="input-field"
            :key="input.key + '12'"
            :style="{ width: 'calc(' + (input.width || '100%') + ' - 10px)' }"
          >
            <label :class="input.className">
              {{ input.title }}
              <span v-if="input.required" class="text-danger">*</span></label
            >
            <div v-if="input.prefix" class="prefix">{{ input.prefix }}</div>
            <div v-if="input.type === 'checkbox-list'" class="pt-4 w-100">
              <div style="max-height: 300px; overflow: auto">
                <div
                  :key="item.id"
                  v-for="(item, idx) in input.selectList"
                  class="p-2"
                >
                  <check-box
                    @change="
                      output[input.key].indexOf(item.id) > -1
                        ? output[input.key].splice(idx, 1)
                        : output[input.key].push(item.id);
                      $forceUpdate();
                    "
                    :value="output[input.key].indexOf(item.id) > -1"
                    class="font-14"
                    ref="inputs"
                    :title="item.name"
                  />
                </div>
              </div>
            </div>
            <auto-complete
              v-model="output[input.key]"
              v-else-if="input.type === 'multi-select'"
              :multi="true"
              ref="inputs"
              :class="{
                error: input.required && !output[input.key],
              }"
              class="w-100"
              @changeOption="
                $forceUpdate();
                input.handleSelect && input.handleSelect(output[input.key]);
              "
              :list="input.selectList"
            />
            <div
              v-else-if="input.type === 'multi'"
              class="border round-6px w-100 pt-3"
            >
              <div class="py-1 px-5px d-flex justify-content-end">
                <input
                  class="form-control form-control-sm h-40px p-2 font-12 box-120"
                  v-model="input.searchKey"
                  placeholder="Tìm kiếm"
                />

                <p
                  class="text-primary bold p-2"
                  @click="
                    input.selectList = input.selectList
                      .map((a) => ({ ...a, selected: false }))
                      .filter((a) => $isInner(a.name, input.searchKey))
                      .map((a) => ({ ...a, selected: true }));

                    output[input.key] = input.selectList.map((a) => a.id);
                    input.handleSelect && input.handleSelect(output[input.key]);
                    $forceUpdate();
                  "
                >
                  Chọn hết
                </p>
              </div>
              <div style="max-height: 300px; overflow: auto">
                <div
                  v-for="o in input.selectList.filter((a) =>
                    $isInner(a.name, input.searchKey)
                  )"
                  :key="o.id"
                  class="pt-1 pl-15px"
                >
                  <check-box
                    v-model="o.selected"
                    @change="
                      output[input.key] = input.selectList
                        .filter((a) => a.selected)
                        .map((a) => a.id);
                      input.handleSelect &&
                        input.handleSelect(output[input.key]);
                    "
                    :title="o.name"
                  />
                </div>
              </div>
            </div>
            <auto-complete
              v-model="output[input.key]"
              v-else-if="input.type === 'select-search'"
              ref="inputs"
              class="w-100"
              @changeOption="
                input.handleSelect && input.handleSelect(output[input.key]);
                $forceUpdate();
              "
              :placeholder="input.title"
              :list="input.selectList"
              :searchFunction="input.searchFunction"
            />
            <select
              :id="getKey()"
              v-else-if="input.type === 'select'"
              :required="input.required"
              v-model="output[input.key]"
              class="form-control"
              :class="{
                error: input.required && !output[input.key],
              }"
              @change="
                input.handleSelect && input.handleSelect(output[input.key]);
                $forceUpdate();
              "
              ref="inputs"
            >
              <option
                v-for="item in input.selectList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
            <div
              :id="getKey()"
              v-else-if="input.type === 'checkbox'"
              class="form-control"
            >
              <check-box
                @change="
                  input.handleSelect && input.handleSelect(output[input.key])
                "
                v-model="output[input.key]"
                class="font-14"
                ref="inputs"
                :title="input.title || input.key"
              />
            </div>
            <div
              :id="getKey()"
              v-else-if="input.type === 'switch'"
              class="form-control"
            >
              <switch-input
                @change="
                  input.handleSelect && input.handleSelect(output[input.key])
                "
                v-model="output[input.key]"
                class="font-14 mr-2"
                ref="inputs"
              />
            </div>
            <div
              :id="getKey()"
              v-else-if="input.type === 'options'"
              class="form-control"
            >
              <options
                @change="
                  input.handleSelect && input.handleSelect(output[input.key])
                "
                :list="input.selectList"
                v-model="output[input.key]"
                ref="inputs"
              />
            </div>
            <textarea
              :id="getKey()"
              v-else-if="input.type === 'textarea'"
              class="form-control"
              :type="input.type || 'text'"
              :placeholder="input.title"
              @change="$forceUpdate()"
              :required="input.required"
              v-model="output[input.key]"
              :rows="input.rows || 5"
              :disabled="input.disabled"
              :class="{
                error: input.required && !output[input.key],
              }"
              ref="inputs"
            />
            <div v-else-if="input.type === 'html'" style="width: 100%">
              <br />
              <ClassicEditor
                :editorId="'edit-' + input.key"
                @change="$forceUpdate()"
                v-model="output[input.key]"
                hideHtml
                width="100%"
                :ref="'edit-' + input.key"
              />
            </div>

            <date-picker
              v-else-if="input.type == 'date-range'"
              class="w100"
              :id="getKey()"
              :class="{
                error: input.required && !output[input.key],
              }"
              :style="{ width: input.width }"
              :required="input.required"
              @change="$forceUpdate()"
              v-model="output[input.key]"
              range
              :shortcuts="
                input.shortcuts ||
                (input.isFuture ? $shortcutDateRangeFuture : $shortcutDateRange)
              "
            />
            <date-picker
              v-else-if="input.type == 'date'"
              class="w100"
              :class="{
                error: input.required && !output[input.key],
              }"
              :id="getKey()"
              :style="{ width: input.width }"
              @change="$forceUpdate()"
              :required="input.required"
              v-model="output[input.key]"
              :disabled="input.disabled"
            />
            <template
              v-else-if="
                input.type == 'price' ||
                input.type == 'money' ||
                input.type == 'quantity'
              "
            >
              <div
                class="position-absolute p-1"
                style="bottom: 0; left: 10px"
                v-if="input.allowPercent"
              >
                <i
                  v-if="output[input.key + 'Type'] == 'PERCENT'"
                  @click="
                    output[input.key + 'Type'] = 'MONEY';
                    $forceUpdate();
                  "
                  class="mdi mdi-percent font-18 text-info pointer"
                  :style="{
                    'pointer-events': input.disabled ? 'none' : 'auto',
                  }"
                />
                <i
                  v-else
                  @click="
                    output[input.key + 'Type'] = 'PERCENT';
                    output[input.key] =
                      output[input.key] > 100 ? 100 : output[input.key];
                    $forceUpdate();
                  "
                  :style="{
                    'pointer-events': input.disabled ? 'none' : 'auto',
                  }"
                  class="mdi mdi-currency-usd font-18 pointer text-primary"
                />
              </div>

              <quantity-input
                v-if="input.type == 'quantity'"
                :id="getKey()"
                :class="{
                  error: input.required && !output[input.key],
                }"
                class="form-control text-right"
                :type="input.type || 'text'"
                :placeholder="input.title"
                :required="input.required"
                :maxlength="input.maxlength || 255"
                :max="input.max"
                :min="input.min || 0"
                :minlength="input.minlength || 0"
                @change="$forceUpdate()"
                :pattern="input.pattern"
                v-model="output[input.key]"
                :disabled="input.disabled"
                ref="inputs"
              />

              <money-input
                v-else
                :id="getKey()"
                class="form-control text-right"
                :class="{
                  error: input.required && !output[input.key],
                }"
                :type="input.type || 'text'"
                :placeholder="input.title"
                :required="input.required"
                :maxlength="input.maxlength || 255"
                :max="input.max"
                @change="$forceUpdate()"
                :min="input.min || 0"
                :minlength="input.minlength || 0"
                :pattern="input.pattern"
                v-model="output[input.key]"
                :disabled="input.disabled"
                ref="inputs"
              />
            </template>
            <div v-else-if="input.type == 'none'" />
            <div v-else-if="input.type == 'file'">
              <img
                v-if="output[input.key]"
                :src="URL.createObjectURL(output[input.key])"
              />
              <input
                :id="getKey()"
                class="form-control"
                type="file"
                :placeholder="input.title"
                :required="input.required"
                :disabled="input.disabled"
                @change="
                  (e) => {
                    output[input.key] = e.target.files[0];
                    $forceUpdate();
                  }
                "
              />
              {{ output[input.key] }}
            </div>
            <div v-else-if="input.type == 'files'" stylee="width: 100%">
              <input
                :id="getKey()"
                class="form-control"
                type="file"
                stylee="width: 100%"
                :placeholder="input.title"
                multiple
                :required="input.required"
                :disabled="input.disabled"
                @change="
                  (e) => {
                    output[input.key] = e.target.files;
                  }
                "
              />
            </div>
            <input
              v-else
              :id="getKey()"
              :class="{
                ['form-control ' + (input.className || '')]: true,
                error: input.required && !output[input.key],
              }"
              :type="input.type || 'text'"
              :placeholder="input.title"
              :required="input.required"
              :maxlength="input.maxlength || 255"
              :max="input.max"
              :min="input.min || 0"
              :minlength="input.minlength || 0"
              :pattern="input.pattern"
              :step="input.step"
              v-model="output[input.key]"
              @change="$forceUpdate()"
              @keypress="input.onKeyPress ? input.onKeyPress($event) : null"
              :disabled="input.disabled"
              ref="inputs"
            />
            <div v-if="input.suffix" class="suffix">{{ input.suffix }}</div>
            <div
              v-if="input.info"
              v-b-tooltip="input.info"
              class="position-absolute right-4px top-30px"
            >
              <i class="mdi mdi-information-outline font-20" />
            </div>
          </div>
        </template>
      </div>
      <div class="form-popup-footer mt-2" v-show="!noShowAction">
        <button
          v-if="isPopup"
          @click="$emit('onClose')"
          type="button"
          class="btn btn-basic pl-3 pr-3"
        >
          <i class="fa fa-times" />
          {{ $t("common.cancel") }}
        </button>
        <button
          :disabled="isSending"
          :class="{ warning: isWarning }"
          type="submit"
          ref="btnSubmit"
          class="btn btn-primary ml-2 pl-3 pr-3"
        >
          <i class="fa fa-check" />
          {{ okText ? okText : $t("common.ok") }}
          <i class="fa fa-spinner fa-pulse fa-spin" v-if="isSending" />
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import AutoComplete from "./AutoComplete.vue";
import CheckBox from "./CheckBox.vue";
import ClassicEditor from "@/components/ClassicEditor.vue";
export default {
  components: { AutoComplete, CheckBox, ClassicEditor },
  props: [
    "form",
    "inputs",
    "actions",
    "okText",
    "isWarning",
    "isSending",
    "noShowAction",
    "isPopup",
  ],
  name: "FromData",
  data() {
    return {
      output: {},
    };
  },
  watch: {
    output: {
      deep: true,
      handler() {
        this.$emit("onChange", this.output);
      },
    },
    inputs() {
      this.inputs.map((i) => {
        if (i.key) {
          if (i.type == "date") {
            this.output[i.key] = new Date(i.value);
          } else {
            this.output[i.key] = i.value;
          }
          // if (i.type == "html" && this.$refs["edit-" + i.key]) {
          //   setTimeout(() => {
          //     this.$refs["edit-" + i.key].update(i.value);
          //   }, 500);
          // }
        }
      });
    },
  },

  updated() {
    // if (this.$refs.inputs && this.$refs.inputs[0]) {
    //   this.$refs.inputs[0].focus();
    // }
  },

  mounted() {
    this.inputs.map((i) => {
      if (i.key) {
        if (i.type == "date") {
          this.output[i.key] = new Date(i.value);
        } else {
          this.output[i.key] = i.value;
          console.log(i.value);
        }
        // if (i.type == "html" && this.$refs["edit-" + i.key]) {
        //   console.log(this.$refs["edit-" + i.key]);
        //   setTimeout(() => {
        //     this.$refs["edit-" + i.key].update(i.value);
        //   }, 500);
        // }
      }
    });
    this.$forceUpdate();
    if (this.$refs.inputs && this.$refs.inputs[0]) {
      this.$refs.inputs[0].focus();
    }
  },
  methods: {
    getKey() {
      return Math.random();
    },
    setData(key, value) {
      this.output[key] = value;
      this.$forceUpdate();
    },
    setAttr(key, type, value) {
      this.inputs.map((i) => {
        if (i.key == key) {
          i[type] = value;
        }
      });
      this.$forceUpdate();
    },
    reset() {
      this.inputs.map((i) => {
        this.output[i.key] = i.value;
        this.$forceUpdate();
      });
    },
    ok() {
      var btn = this.$refs.btnSubmit;
      btn.click();
      return this.output;
    },
    onSubmit() {
      this.$emit("onSubmit", this.output);
    },
  },
};
</script>
